import { AxiosRequestConfig } from 'axios';
import { coreApi } from '..';

export const getPushPreferences = async (options: AxiosRequestConfig) => {
  try {
    const { data } = await coreApi.get(
      '/api/preferences/pushPreferences',
      options
    );

    return data;
  } catch (e) {
    console.error(e);
  }
};

import React from 'react';
import { navigate } from 'gatsby';

import { styled } from 'stitches.config';
import { Project } from '@src/models/project';
import {
  filterGameType,
  getProjectDetailUrl,
  getProjectMultipleBadgeType,
} from '@src/constants/project';
import { BADGE_COLOR, BADGE_TEXT } from '@src/constants/badge';
import LockIcon from '@assets/icons/lock-icon.svg';
import { ClickEventName, track } from '@src/track';

interface ProjectsItemProps {
  project: Project;
  openPreparingOrLockedModal: (isLockedOrPreparing: boolean) => unknown;
}

export function HeaderSwiperItem({
  project,
  openPreparingOrLockedModal,
}: ProjectsItemProps) {
  const { verticalImageUrl, isLockedDefault, isPreparing } = project;

  const badgeTypes = React.useMemo(
    () => getProjectMultipleBadgeType(project),
    [project]
  );

  const handleItemClick = () => {
    track.onClick({
      clickEventName: ClickEventName.click_project,
      params: {
        projectName: project.name,
        projectId: project.id,
      },
    });
    navigate(getProjectDetailUrl(project));
  };
  return (
    <Container onClick={handleItemClick}>
      <Label>{filterGameType(project)}</Label>
      <ImageContainer css={{ backgroundImage: `url('${verticalImageUrl}')` }}>
        {(isLockedDefault || isPreparing) && (
          <Locked>
            <img src={LockIcon} />
          </Locked>
        )}
      </ImageContainer>
      <BadgeContainer>
        {badgeTypes.map((badge, index) => (
          <BadgeItem key={index} css={{ backgroundColor: BADGE_COLOR[badge] }}>
            {BADGE_TEXT[badge]}
          </BadgeItem>
        ))}
      </BadgeContainer>
    </Container>
  );
}

const Container = styled('div', {
  height: '120%',
  cursor: 'pointer',
});

const Label = styled('div', {
  display: 'inline-block',
  position: 'relative',
  top: '42px',
  left: '-6px',
  height: '26px',
  padding: '4px 10px 4px 8px',
  lineHeight: '18px',
  borderRadius: '5px 15px 15px 5px',
  backgroundColor: '#fff',
  color: 'rgb(40, 47, 137)',
  fontSize: '12px',
  fontWeight: 'bold',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  zIndex: 1,

  '@bpMin992': {
    fontSize: '13px',
  },
});

const ImageContainer = styled('div', {
  position: 'relative',
  height: '320px',
  borderRadius: '5px',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  overflow: 'hidden',
});

const BadgeContainer = styled('div', {
  display: 'flex',
  position: 'absolute',
  bottom: '10px',
  right: '10px',
});

const BadgeItem = styled('div', {
  padding: '2px 4px',
  marginRight: '4px',
  fontSize: '10px',
  fontWeight: 700,
  borderRadius: '4px',
  color: '#fff',

  '@bpMin992': {
    fontSize: '12px',
  },
});

const Locked = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
});

import React from 'react';

import { ProjectContentSkeleton } from './ProjectContentSkeleton';
import { ProjectSwiperSkeleton } from './ProjectSwiperSkeleton';
import { ProjectPageContainer } from '@src/styles/global';

export function ProjectPageSkeleton() {
  return (
    <>
      <ProjectSwiperSkeleton />
      <ProjectPageContainer>
        <ProjectContentSkeleton isHomeTab={true} />
      </ProjectPageContainer>
    </>
  );
}

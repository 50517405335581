import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';

import Banner from '@src/models/banner';
import { styled } from 'stitches.config';
import { Autoplay } from 'swiper';

interface BannerSwiperProps {
  data: Banner[] | undefined;
}

const autoplayConfig = {
  delay: 2000,
  disableOnInteraction: false,
};

export function BannerSwiper({ data }: BannerSwiperProps) {
  const [currentIndex, setCurrentIndex] = React.useState(1);

  return (
    <Swiper
      modules={[Autoplay]}
      slidesPerView={'auto'}
      speed={300}
      loop={true}
      centeredSlides={true}
      autoHeight={true}
      slidesPerGroup={1}
      autoplay={autoplayConfig}
      spaceBetween={window?.matchMedia('(max-width: 767px)') ? 0 : -32}
      onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex + 1)}
    >
      {data?.map((item, index) => (
        <SwiperSlide
          className="banners-swiper-slide"
          key={index}
          style={{ textAlign: 'center' }}
        >
          <a href={item.linkUrl.replace('?inApp=true', '')}>
            <BannerImageContainer>
              <BannerImage
                css={{ $$backgroundImage: `url('${item.imageUrl}')` }}
              />
            </BannerImageContainer>
          </a>
        </SwiperSlide>
      ))}
      <BannerIndicator>
        {currentIndex}&nbsp;/&nbsp;
        <span style={{ color: '#c3c3c3' }}>{data?.length}</span>
      </BannerIndicator>
    </Swiper>
  );
}

const BannerImageContainer = styled('div', {
  paddingBottom: '35%',
  position: 'relative',
  margin: '0 20px',
  borderRadius: '5px',
  overflow: 'hidden',

  '@bpMax767': {
    paddingBottom: '40%',
    margin: 0,
  },
});

const BannerImage = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  borderRadius: '5px',
  backgroundImage: '$$backgroundImage',
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
});

const BannerIndicator = styled('div', {
  position: 'absolute',
  height: '17px',
  bottom: '14px',
  right: '24%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0 10px',
  borderRadius: '9px',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  color: '#fff',
  fontSize: '10px',
  fontWeight: 'bold',
  lineHeight: 2.6,
  letterSpacing: 'normal',
  zIndex: 1,

  '@bpMax767': {
    right: '12px',
  },
});

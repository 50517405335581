import React from 'react';
import ContentLoader from 'react-content-loader';

interface ProjectContentSkeletonProps {
  isHomeTab: boolean;
}

export const ProjectContentSkeleton = ({
  isHomeTab,
}: ProjectContentSkeletonProps) => {
  return (
    <>
      <ContentLoader
        speed={0.8}
        width="100%"
        height="1000"
        backgroundColor="#f4f4f4"
        foregroundColor="#ecebeb"
      >
        {isHomeTab ? (
          <>
            <rect x="0" y="30" rx="8" ry="8" width="30%" height="30" />

            <rect x="8%" y="90" rx="8" ry="8" width="15%" height="100" />
            <rect x="31%" y="90" rx="8" ry="8" width="15%" height="100" />
            <rect x="54%" y="90" rx="8" ry="8" width="15%" height="100" />
            <rect x="77%" y="90" rx="8" ry="8" width="15%" height="100" />

            <rect x="8%" y="200" rx="8" ry="8" width="15%" height="100" />
            <rect x="31%" y="200" rx="8" ry="8" width="15%" height="100" />
            <rect x="54%" y="200" rx="8" ry="8" width="15%" height="100" />
            <rect x="77%" y="200" rx="8" ry="8" width="15%" height="100" />

            <rect x="0" y="400" rx="8" ry="8" width="30%" height="30" />
            <rect x="0" y="460" rx="8" ry="8" width="200" height="200" />
            <rect x="210" y="460" rx="8" ry="8" width="200" height="200" />
            <rect x="420" y="460" rx="8" ry="8" width="200" height="200" />
            <rect x="630" y="460" rx="8" ry="8" width="200" height="200" />

            <rect x="0" y="700" rx="8" ry="8" width="30%" height="30" />
            <rect x="0" y="760" rx="8" ry="8" width="200" height="200" />
            <rect x="210" y="760" rx="8" ry="8" width="200" height="200" />
            <rect x="420" y="760" rx="8" ry="8" width="200" height="200" />
            <rect x="630" y="760" rx="8" ry="8" width="200" height="200" />
          </>
        ) : (
          <>
            <rect x="0" y="30" rx="8" ry="8" width="30%" height="30" />
            <rect x="0" y="90" rx="8" ry="8" width="200" height="200" />
            <rect x="210" y="90" rx="8" ry="8" width="200" height="200" />
            <rect x="420" y="90" rx="8" ry="8" width="200" height="200" />
            <rect x="630" y="90" rx="8" ry="8" width="200" height="200" />

            <rect x="0" y="330" rx="8" ry="8" width="30%" height="30" />
            <rect x="0" y="390" rx="8" ry="8" width="200" height="200" />
            <rect x="210" y="390" rx="8" ry="8" width="200" height="200" />
            <rect x="420" y="390" rx="8" ry="8" width="200" height="200" />
            <rect x="630" y="390" rx="8" ry="8" width="200" height="200" />

            <rect x="0" y="630" rx="8" ry="8" width="30%" height="30" />
            <rect x="0" y="690" rx="8" ry="8" width="200" height="200" />
            <rect x="210" y="690" rx="8" ry="8" width="200" height="200" />
            <rect x="420" y="690" rx="8" ry="8" width="200" height="200" />
            <rect x="630" y="690" rx="8" ry="8" width="200" height="200" />
          </>
        )}
      </ContentLoader>
    </>
  );
};

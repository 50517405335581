import { coreApi } from '..';
import Section from '@src/models/section';

interface getTabContentDataResponse {
  body: Section[];
}

export async function getTabContentData(tab: string) {
  const { data } = await coreApi.get<getTabContentDataResponse>(
    `/api/mainpage/${tab}`
  );

  return data;
}

import useSWR from 'swr';
import 'swiper/css/effect-coverflow';

import { Tab } from '../../Tab';
import { HeaderSwiper } from '@src/components/HeaderSwiper';
import { getProjectsData } from '@src/apis/core/getProjectsData';
import { ProjectPageSkeleton } from '@src/components/Skeleton';
import { getMenuData } from '@src/apis/core/getMenuData';
import Modal, { useModal } from '@src/components/Modal';
import { PreparingOrLockedModal } from '@src/components/HeaderSwiper/PreparingModal';
import React from 'react';
import { getPushPreferences } from '@src/apis/core/getPushPreferences';
import { getLocalStorage } from '@src/utils/localStorage';
import { postPushPreferences } from '@src/apis/core/postPushPreferences';

export default function ProjectsPage() {
  const { data: projectsData } = useSWR(
    '/api/mainpage/header',
    getProjectsData
  );

  const { data: menuData } = useSWR('/api/mainpage/menuInfo', getMenuData);

  const { modal, openModal, closeModal } = useModal();

  const openPreparingOrLockedModal = (isLockedOrPreparing: boolean) => {
    if (!isLockedOrPreparing) return;
    openModal(<PreparingOrLockedModal onClose={closeModal} />);
  };

  React.useEffect(() => {
    const accessToken = getLocalStorage('accessToken');

    const setPushPreference = async () => {
      try {
        const data = await getPushPreferences({
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        });

        if (!data) {
          await postPushPreferences({
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });
        }
      } catch (e) {
        console.error(e);
      }
    };

    setPushPreference();
  }, []);

  return (
    <>
      {!projectsData ? (
        <ProjectPageSkeleton />
      ) : (
        <HeaderSwiper
          data={projectsData}
          openPreparingOrLockedModal={openPreparingOrLockedModal}
        />
      )}
      {menuData && <Tab menuData={menuData} />}
      <Modal {...modal} />
    </>
  );
}

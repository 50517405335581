import React, { useEffect, useState } from 'react';
import { animated, useSprings } from '@react-spring/web';
import { Link, navigate } from 'gatsby';
import { styled } from 'stitches.config';
import { BannerSwiper } from '../BannerSwiper';
import { ProjectList } from '../ProjectList';
import { ProjectPageContainerWithoutPadding } from '@src/styles/global';
import Banner from '@src/models/banner';
import Section from '@src/models/section';
import { Genre } from '@src/models/menuInfo';

import seongsuIcon from '@assets/icons/seongsu.svg';
import newIcon from '@assets/icons/new.svg';
import popularIcon from '@assets/icons/popular.svg';
import snackIcon from '@assets/icons/snack.svg';
import comicIcon from '@assets/icons/comic.svg';
import horrorIcon from '@assets/icons/horror.svg';
import crimeSceneIcon from '@assets/icons/crime-scene.svg';

import touchingDramaIcon from '@assets/icons/touching-drama.svg';
import educationHistoryIcon from '@assets/icons/education-history.svg';
import detectiveIcon from '@assets/icons/detective.svg';
import fantasySfIcon from '@assets/icons/fantasy-sf.svg';
import sfIcon from '@assets/icons/sf-legacy.svg';
import multiIcon from '@assets/icons/multi.svg';

import dramaIconLegacy from '@assets/icons/touching-drama.svg';
import educationalIconLegacy from '@assets/icons/history.svg';
// import detectiveIconLegacy from '@assets/icons/detective.svg';
import fantasyIconLegacy from '@assets/icons/fantasy-legacy.svg';
import sfIconLegacy from '@assets/icons/sf-legacy.svg';
import multiIconLegacy from '@assets/icons/multi-legacy.svg';

import { deepLinkGenresValue } from '@src/models/menuInfo';
import { FeatureProvider } from '@src/providers/Feature';
import { FeatureFlag } from '@src/constants/feature';
import Modal, { useModal } from '../Modal';
import { PreparingOrLockedModal } from '../HeaderSwiper/PreparingModal';
import { keyframes } from '@stitches/react';
import { useGetRoomsQuery } from '@src/apis/queries';

interface GenreContentType {
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

type genreType = {
  [index: string]: GenreContentType;
  seongsu: GenreContentType;
  new: GenreContentType;
  popular: GenreContentType;
  snack: GenreContentType;
  multiPlay: GenreContentType;
  comic: GenreContentType;
  'touching,drama': GenreContentType;
  'education,history': GenreContentType;
  'detective,thriller': GenreContentType;
  'fantasy,scifi': GenreContentType;
  'sci-fi': GenreContentType;
  horror: GenreContentType;
  clue: GenreContentType;
};

type genreTypeLegacy = {
  [index: string]: GenreContentType;
  seongsu: GenreContentType;
  new: GenreContentType;
  popular: GenreContentType;
  snack: GenreContentType;
  multiPlay: GenreContentType;
  comic: GenreContentType;
  drama: GenreContentType;
  educational: GenreContentType;
  detective: GenreContentType;
  fantasy: GenreContentType;
  'sci-fi': GenreContentType;
  horror: GenreContentType;
  clue: GenreContentType;
};

const genreContent: genreType = {
  seongsu: { icon: seongsuIcon },
  new: { icon: newIcon },
  popular: { icon: popularIcon },
  snack: { icon: snackIcon },
  multiPlay: { icon: multiIcon },
  comic: { icon: comicIcon },
  'touching,drama': { icon: touchingDramaIcon },
  'education,history': { icon: educationHistoryIcon },
  'detective,thriller': { icon: detectiveIcon },
  'fantasy,scifi': { icon: fantasySfIcon },
  'sci-fi': { icon: sfIcon },
  horror: { icon: horrorIcon },
  clue: { icon: crimeSceneIcon },
};

const genreContentLegacy: genreTypeLegacy = {
  seongsu: { icon: seongsuIcon },
  new: { icon: newIcon },
  popular: { icon: popularIcon },
  snack: { icon: snackIcon },
  multiPlay: { icon: multiIconLegacy },
  comic: { icon: comicIcon },
  drama: { icon: dramaIconLegacy },
  educational: { icon: educationalIconLegacy },
  detective: { icon: detectiveIcon },
  fantasy: { icon: fantasyIconLegacy },
  'sci-fi': { icon: sfIconLegacy },
  horror: { icon: horrorIcon },
  clue: { icon: crimeSceneIcon },
};

interface TabContentProps {
  genres: Genre[];
  banners: Banner[];
  contents: Section[];
  currentIndex: number;
}

const items = [
  'https://cdn.realworld.to/user-files/HwFaQoJ1KqFHC9W_OrWqOA/22-03-15-07-30-31_CUqqQFtYorM.jpg',
  'https://cdn.realworld.to/user-files/C1XvvSCCgcltiScMpGSlyw/23-05-25-06-22-42_GxVWOUfZxbI.jpg',
  'https://cdn.realworld.to/user-files/9jiog-NZZ4xljQnBb4yjsw/23-03-22-05-15-19_Z8iNIqdjfM4.png',
  'https://cdn.realworld.to/user-files/PCsWTksSZkJUwvQ_MnOW9A/23-05-02-03-45-18_6pUljV4KMGE.jpg',
];

export function TabContent({
  genres,
  banners,
  contents,
  currentIndex,
}: TabContentProps) {
  const isKorean = navigator.language.substring(0, 2) == 'ko';

  const { openModal, closeModal, modal } = useModal();
  const { data: rooms } = useGetRoomsQuery();

  const availableRoomImageUrls = rooms?.pages
    .map((page) => page?.content)
    .flat()
    .filter((room) => room?.status === 'Waiting' || room?.status === 'Playing')
    .map((room) => room?.roomProject.imageUrl || '')
    .reduce((acc, cur, index, array) => {
      if (index === array.length - 1) {
        return acc.concat(cur).concat(items.slice(0, 3 - index));
      }
      return acc.concat(cur);
    }, [] as string[]);

  const handleClick = () => navigate('/play/rooms');

  const [playRoomCircleImageUrls, setPlayRoomCircleImageUrls] = useState(
    availableRoomImageUrls || items
  );

  const [springs, api] = useSprings(
    playRoomCircleImageUrls.length,
    (index) => ({
      config: { tension: 300, friction: 80 },
      zIndex: 10 - index,
      x: -67 * index,
      opacity: 1,
      display: 'block',
    })
  );
  useEffect(() => {
    api.start((index) => ({
      zIndex: 10 - playRoomCircleImageUrls.indexOf(items[index]),
      x: -67 * playRoomCircleImageUrls.indexOf(items[index]),
      opacity: playRoomCircleImageUrls.indexOf(items[index]) < 3 ? 1 : 0,
      display:
        playRoomCircleImageUrls.indexOf(items[index]) < 3 ? 'block' : 'none',
    }));
  }, [playRoomCircleImageUrls]);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlayRoomCircleImageUrls((prev) => {
        const firstItem = prev.shift();
        if (firstItem) prev.push(firstItem);

        return [...prev];
      });
    }, 1800);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      <ProjectPageContainerWithoutPadding>
        {isKorean && currentIndex === 0 && (
          <>
            <PlayRoomBanner onClick={handleClick}>
              <PlayRoomBannerFlexContainer>
                <div>
                  <PlayRoomBannerTitle>
                    플레이룸 찾기
                    <Beta>Beta</Beta>
                  </PlayRoomBannerTitle>
                  <PlayRoomBannerSubTitle>
                    다양한 사람들과의 실시간 소통!
                  </PlayRoomBannerSubTitle>
                </div>
                <RoomCircleThumbnailGroup>
                  {/* TODO: 방 목록 API 연동 */}
                  {springs.map(({ x, zIndex, opacity, display }, index) => (
                    <RoomCircleThumbnail
                      src={availableRoomImageUrls?.[index] || items[index]}
                      alt={`image${index}`}
                      key={`image${index}`}
                      style={{
                        zIndex,
                        display,
                        transform: x.to((x) => `translateX(${x}%)`),
                        opacity,
                        position: 'absolute',
                        right: 0,
                      }}
                    ></RoomCircleThumbnail>
                  ))}
                </RoomCircleThumbnailGroup>
              </PlayRoomBannerFlexContainer>
            </PlayRoomBanner>
            <GenresTitle>새로운 장르 탐색을 시작해보세요.</GenresTitle>
            <GenresContainer>
              <FeatureProvider feature={FeatureFlag.CRIME_SCENE_GENRE}>
                {(isOn) => (
                  <FeatureProvider feature={FeatureFlag.CRIME_SCENE_GENRE_OPEN}>
                    {(isCrimeSceneOpenOn) =>
                      genres.map((genre) => {
                        if (deepLinkGenresValue.includes(genre.value)) {
                          return (
                            <>
                              <GenresItem key={genre.value}>
                                <a
                                  onClick={() => {
                                    window.location.href =
                                      'https://rwd.to/3mRUHWGYpE ';
                                    // isCrimeSceneOpenOn
                                    //   ? (window.location.href = `https://go.rwd.to/?link=https%3a%2f%2frealworld.to%2fgenre%2f${encodeURI(
                                    //       // FIXME: 모바일 딥링크에 따른 임시 수정
                                    //       'crimeScene'
                                    //     )}&apn=biz.uniquegood.realworld.sunguard&afl=${encodeURI(
                                    //       'https://play.google.com/store/apps/details?id=biz.uniquegood.realworld.sunguard'
                                    //     )}&ibi=biz.uniquegood.RealWorld.SunGuard&ifl=${encodeURI(
                                    //       'https://apps.apple.com/kr/app/id1391415522'
                                    //     )}`)
                                    //   : openModal(
                                    //       <PreparingOrLockedModal
                                    //         onClose={closeModal}
                                    //         message="3월 29일 오전 10:00 OPEN 예정"
                                    //       />
                                    //     );
                                  }}
                                >
                                  <GenreIcon
                                    src={genreContent[
                                      genre.value
                                    ]?.icon?.toString()}
                                    alt={`${genre.name} 아이콘`}
                                  />
                                  <GenreName>{genre.name}</GenreName>
                                </a>
                              </GenresItem>
                            </>
                          );
                        }

                        return (
                          <GenresItem key={genre.value}>
                            <Link to={`/genre/${genre.value}`}>
                              {isOn ? (
                                <GenreIcon
                                  src={genreContent[
                                    genre.value
                                  ]?.icon?.toString()}
                                  alt={`${genre.name} 아이콘`}
                                />
                              ) : (
                                <GenreIcon
                                  src={genreContentLegacy[
                                    genre.value
                                  ]?.icon?.toString()}
                                  alt={`${genre.name} 아이콘`}
                                />
                              )}

                              <GenreName>{genre.name}</GenreName>
                            </Link>
                          </GenresItem>
                        );
                      })
                    }
                  </FeatureProvider>
                )}
              </FeatureProvider>
            </GenresContainer>
            {banners.length > 0 && <BannerSwiper data={banners} />}
          </>
        )}
        <Content>
          {contents.map((content, index) => (
            <React.Fragment key={index}>
              {content.imageUrl ? (
                <ImageBannerLink draggable={false} href={content.linkUrl}>
                  <img draggable={false} src={content.imageUrl} />
                </ImageBannerLink>
              ) : (
                <ContentSection>
                  <ProjectContainer>
                    <ProjectTitle>{content.name}</ProjectTitle>
                    <ProjectList
                      type={content.displayType}
                      projects={content.projects}
                    />
                  </ProjectContainer>
                </ContentSection>
              )}
            </React.Fragment>
          ))}
        </Content>
      </ProjectPageContainerWithoutPadding>
      <Modal {...modal} />
    </>
  );
}

const Content = styled('div', {
  backgroundColor: '#f4f4f4',
});

const ContentSection = styled('div', {
  marginBottom: '10px',
  backgroundColor: '#fff',

  '@bpMax767': {
    paddingLeft: '1rem',
  },
});

const ImageBannerLink = styled('a', {
  display: 'block',
  position: 'relative',
  marginTop: '-10px',

  '&:last-child': {
    marginBottom: '30px',
  },

  'a + &': {
    marginTop: '24px',
  },

  img: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const GenresContainer = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  marginBottom: '20px',
  textAlign: 'center',
});

const GenresTitle = styled('div', {
  marginTop: '16px',
  marginBottom: '20px',
  paddingLeft: '1rem',
  fontSize: '21px',
  fontWeight: 700,
});

const GenresItem = styled('div', {
  width: '25%',
  paddingBottom: '15px',
});

const GenreIcon = styled('img', {
  width: '52px',
  height: '52px',
});

const GenreName = styled('div', {
  color: '#2e2e2e',
  fontSize: '14px',
  letterSpacing: 'normal',
  lineHeight: '1.2',
});

const ProjectContainer = styled('div', {
  paddingTop: '24px',
});

const ProjectTitle = styled('h1', {
  marginBottom: '20px',
  fontSize: '21px',
  fontWeight: 700,
  lineHeight: '28px',
});

const PlayRoomBanner = styled('div', {
  position: 'relative',
  width: '100%',
  padding: '19px 16px',
  marginTop: '16px',
  borderRadius: '16px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  cursor: 'pointer',
});

const PlayRoomBannerTitle = styled('h2', {
  display: 'flex',
  alignItems: 'center',
  marginBottom: '4px',
  fontSize: '18px',
  fontWeight: 700,
  color: '#191919',
});

const PlayRoomBannerSubTitle = styled('span', {
  fontSize: '12px',
  fontWeight: 400,
  color: '#515253',
});

const PlayRoomBannerFlexContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const rolling = keyframes({
  '0%': { transform: '' },
  '100%': { transform: '' },
});

const RoomCircleThumbnailGroup = styled('div', {
  position: 'relative',
  width: '144px',
  height: '48px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
});

const RoomCircleThumbnail = styled(animated.img, {
  position: 'absolute',
  right: '0',
  width: '48px',
  height: '48px',
  borderRadius: '50%',
  border: '2px solid var(--white, #FFF)',
  objectFit: 'cover',
});

const Beta = styled('span', {
  padding: '3px 8px',
  marginLeft: '4px',
  fontSize: '12px',
  fontWeight: 700,
  lineHeight: '14px',
  color: '#c869ff',
  backgroundColor: '#F9F0FF',
  borderRadius: '999px',
});

import React from 'react';

import { GeneralHead } from '@src/components/GeneralHead';
import ProjectsPage from '@src/components/pages/Projects';
import { NavigationBar } from '@src/components/NavigationBar';
import { NavigationBarType } from '@src/constants/constant';
import { Footer } from '@src/components/Footer';
import { initLanguage } from '@src/utils/translation';
import { Layout } from '@src/components/Layout';
import { useQueryString } from '@src/hooks/useQueryString';

export default function Projects() {
  const { isPreparing } = useQueryString();

  React.useEffect(() => {
    initLanguage();
  }, []);

  return (
    !isPreparing && (
      <Layout
        header={({ scrollElement }) => (
          <NavigationBar
            type={NavigationBarType.GAME}
            scrollRef={scrollElement}
          />
        )}
        footer={<Footer />}
      >
        {<ProjectsPage />}
      </Layout>
    )
  );
}

export const Head = () => {
  return (
    <>
      <GeneralHead />
      <title>게임 목록 - 리얼월드</title>
    </>
  );
};

import React from 'react';
import ContentLoader from 'react-content-loader';

import { useIsMobile } from '../../hooks/useIsMobile';

export const ProjectSwiperSkeleton = () => {
  const isMobile = useIsMobile({ breakpoint: 768 });

  return (
    <ContentLoader
      speed={0.8}
      width="100vw"
      height="500"
      backgroundColor="#f4f4f4"
      foregroundColor="#ecebeb"
    >
      {isMobile ? (
        <>
          <rect x="0" y="132" rx="4" ry="4" width="15%" height="240" />
          {/* 맨 왼쪽 포스터 */}
          <rect x="25%" y="92" rx="8" ry="8" width="50%" height="320" />
          {/* 중앙 포스터 */}/
          <rect x="85%" y="132" rx="4" ry="4" width="15%" height="240" />
          {/* 맨 오른쪽 포스터 */}
        </>
      ) : (
        <>
          <rect x="4%" y="132" rx="4" ry="4" width="11%" height="240" />
          {/* 맨 왼쪽 포스터 */}
          <rect x="20%" y="112" rx="4" ry="4" width="15%" height="280" />
          {/* 왼쪽 포스터 */}
          <rect x="40%" y="92" rx="8" ry="8" width="20%" height="320" />
          {/* 중앙 포스터 */}
          <rect x="65%" y="112" rx="4" ry="4" width="15%" height="280" />
          {/* 오른쪽 포스터 */}
          <rect x="85%" y="132" rx="4" ry="4" width="11%" height="240" />
          {/* 맨 오른쪽 포스터 */}
        </>
      )}
    </ContentLoader>
  );
};

import { AxiosRequestConfig } from 'axios';
import { coreApi } from '..';

export const postPushPreferences = async (options: AxiosRequestConfig) => {
  try {
    const { data } = await coreApi.post(
      '/api/preferences/pushPreferences',
      {
        isReceiveMarketingNotification: false,
        isReceiveNightMarketingNotification: false,
        isReceiveChatbotNotification: true,
        isReceiveCommunityNotification: true,
      },
      options
    );

    return data;
  } catch (e) {
    console.error(e);
  }
};

import React from 'react';
import { Link } from 'gatsby';

import { styled } from 'stitches.config';
import { Badge } from '@src/components/Badge';
import { Tag } from '@src/components/Tag';
import {
  filterGameType,
  filterPrice,
  getIsHaveLocation,
  getProjectSingleBadgeType,
  getProjectDetailUrl,
} from '@src/constants/project';
import { Project } from '@src/models/project';
import LocationIcon from '@assets/icons/location.svg';
import { ClickEventName, track } from '@src/track';

interface HorizontalScrollProjectItemProps {
  project: Project;
}

export function HorizontalScrollProjectItem({
  project,
}: HorizontalScrollProjectItemProps) {
  const filteredPrice = React.useMemo(() => filterPrice(project), [project]);
  const badgeType = React.useMemo(
    () => getProjectSingleBadgeType(project),
    [project]
  );

  const handleItemClick = () => {
    track.onClick({
      clickEventName: ClickEventName.click_project,
      params: {
        projectName: project.name,
        projectId: project.id,
      },
    });
  };

  return (
    <Link to={getProjectDetailUrl(project)}>
      <div onClick={handleItemClick}>
        <ImageWrapper>
          <HorizontalScrollListImage src={project.imageUrl} />
          {badgeType && <Badge type={badgeType} />}
        </ImageWrapper>
        <div>
          <Tag
            text={filterGameType(project)}
            iconUrl={getIsHaveLocation(project) ? LocationIcon : undefined}
          />
          <ProjectName>{project.name}</ProjectName>
          <div>
            <ProjectPrice>
              {filteredPrice.discount && (
                <Discount>{filteredPrice.discount}</Discount>
              )}
              <span>{filteredPrice.price}</span>
              <ProjectOriginalPrice>
                {filteredPrice.originalPrice}
              </ProjectOriginalPrice>
            </ProjectPrice>
          </div>
        </div>
      </div>
    </Link>
  );
}

const ImageWrapper = styled('div', {
  position: 'relative',
  width: '100%',
  paddingTop: '80%',
  marginBottom: '8px',
});

const HorizontalScrollListImage = styled('img', {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  objectFit: 'cover',
});

const ProjectName = styled('h2', {
  display: '-webkit-box',
  maxHeight: '46px',
  fontSize: '18px',
  fontWeight: '700',
  lineHeight: '23px',
  wordWrap: 'break-word',
  '-webkit-line-clamp': 2,
  '-webkit-box-orient': 'vertical',

  overflow: 'hidden',
  textOverflow: 'ellipsis',

  '@bpMax767': {
    fontSize: '16px',
  },
});

const ProjectPrice = styled('div', {
  fontSize: '14px',
  fontWeight: '500',
  color: '#626262',
  lineHeight: '20px',

  '& :first-of-type': {
    marginRight: '4px',
  },
});

const ProjectOriginalPrice = styled('div', {
  fontSize: '13px',
  color: '#c3c3c3',
  textDecorationLine: 'line-through',
});

const Discount = styled('span', {
  color: '#c869ff',
});

import React from 'react';

import { styled } from 'stitches.config';
import { Tab } from '@src/models/menuInfo';

interface TabListProps {
  tabs: Tab[];
  currentIndex: number;
  setCurrentIndex: React.Dispatch<React.SetStateAction<number>>;
}

export function TabList({ tabs, currentIndex, setCurrentIndex }: TabListProps) {
  return (
    <Container>
      <InnerContainer>
        {tabs.map((tab, index) => (
          <TabItem
            key={tab.value}
            onClick={() => setCurrentIndex(index)}
            isSelected={index === currentIndex}
          >
            {tab.name}
          </TabItem>
        ))}
      </InnerContainer>
    </Container>
  );
}

const Container = styled('div', {
  position: 'sticky',
  top: '56px',
  width: '100%',
  borderBottom: '1px solid #ededed',
  backgroundColor: '#fff',
  zIndex: 2,
});

const InnerContainer = styled('ul', {
  width: '100%',
  margin: '0 auto',
  padding: '0 1.5rem',

  listStyle: 'none',

  '@bpMin1200': {
    maxWidth: '1140px',
  },
  '@bpMax1199': {
    maxWidth: '960px',
  },
  '@bpMax991': {
    maxWidth: '720px',
  },
  '@bpMax767': {
    maxWidth: '540px',
  },
});

const TabItem = styled('li', {
  display: 'inline-block',
  color: '#626262',
  fontSize: '17px',
  lineHeight: '21px',
  fontWeight: 500,
  marginLeft: '16px',
  padding: '8px 7px',
  border: 0,
  cursor: 'pointer',

  '&:first-child': {
    marginLeft: 0,
  },

  variants: {
    isSelected: {
      true: {
        marginBottom: '-1px',
        paddingBottom: '7px',
        color: '#000',
        borderBottom: '2px solid #000',
        fontWeight: 'bold',
      },
      false: {},
    },
  },
});

import { Project } from '@src/models/project';
import { coreApi } from '..';

type getProjectsDataResponse = Project[];

export async function getProjectsData() {
  const { data } = await coreApi.get<getProjectsDataResponse>(
    '/api/mainpage/header'
  );

  return data;
}

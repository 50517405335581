import { coreApi } from '..';
import Banner from '@src/models/banner';

type getBannersDataResponse = Banner[];

export async function getBannersData() {
  const { data } = await coreApi.get<getBannersDataResponse>(
    '/api/mainpage/banners'
  );

  return data;
}

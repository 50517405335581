import { styled } from 'stitches.config';
import { ContainerWithMediaQuery } from './media';

export const DefaultLayout = styled('div', {
  position: 'relative',
  width: '100%',
  height: '100%',
});

export const PageContentLayout = styled('div', {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  height: '100%',
  overflow: 'auto',
});

export const ProjectPageContainer = styled(ContainerWithMediaQuery, {
  position: 'relative',
  width: '100%',
  margin: '0 auto',
  padding: '0 1.5rem',
  overflow: 'hidden',
});

export const ProjectPageContainerWithoutPadding = styled(
  ContainerWithMediaQuery,
  {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    overflow: 'hidden',

    '@bpMin768': {
      padding: '0 1.5rem',
    },
  }
);

export const PageContainer = styled('div', {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
});

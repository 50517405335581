import React from 'react';
import SwiperCore, { Autoplay, EffectCoverflow } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Project } from '@src/models/project';
import { HeaderSwiperItem } from './HeaderSwiperItem';
import { styled } from 'stitches.config';

const coverflowEffectConfig = {
  slideShadows: false,
  rotate: 0,
  stretch: 0,
  depth: 100,
};

const autoplayConfig = {
  delay: 5000,
  disableOnInteraction: false,
};

interface HeaderSwiperProps {
  data: Project[];
  openPreparingOrLockedModal: (isLockedOrPreparing: boolean) => unknown;
}

export function HeaderSwiper({
  data,
  openPreparingOrLockedModal,
}: HeaderSwiperProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const currentBackgroundImage = React.useMemo(() => {
    return data && data[currentIndex].imageUrl;
  }, [data, currentIndex]);

  const handleSlideChange = (swiper: SwiperCore) => {
    setCurrentIndex(swiper.realIndex);
  };

  return (
    <ProjectsSwiperContainer>
      <ProjectVisibleArea>
        <Swiper
          modules={[EffectCoverflow, Autoplay]}
          effect={'coverflow'}
          slidesPerView={'auto'}
          speed={700}
          loop={true}
          centeredSlides={true}
          autoHeight={true}
          slidesPerGroup={1}
          coverflowEffect={coverflowEffectConfig}
          autoplay={autoplayConfig}
          onSlideChange={handleSlideChange}
        >
          {data.map((project) => (
            <SwiperSlide key={project.id} className="projects-swiper-slide">
              <HeaderSwiperItem
                project={project}
                openPreparingOrLockedModal={openPreparingOrLockedModal}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </ProjectVisibleArea>
      <BackgroundImage src={currentBackgroundImage} />
    </ProjectsSwiperContainer>
  );
}

const ProjectsSwiperContainer = styled('div', {
  position: 'relative',
  width: '100%',
  height: '436px',
  paddingTop: '56px',
  margin: '0 auto',
  overflow: 'hidden',

  '&::before': {
    content: '',
    position: 'absolute',
    top: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    zIndex: 1,
  },
});

const ProjectVisibleArea = styled('div', {
  position: 'relative',
  maxWidth: '1320px',
  height: '100%',
  margin: '0 auto',
});

const BackgroundImage = styled('img', {
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'top center',
  filter: 'blur(10px)',
});

import { Genre, Tab } from '@src/models/menuInfo';
import { coreApi } from '..';

interface getMenuDataResponse {
  tabs: Tab[];
  genres: Genre[];
}

export async function getMenuData() {
  const { data } = await coreApi.get<getMenuDataResponse>(
    '/api/mainpage/menuInfo'
  );

  return data;
}

import React from 'react';
import useSWR from 'swr';

import { TabContent } from './TabContent';
import { TabList } from './TabList';
import { getTabContentData } from '@src/apis/core/getTabContentData';
import { getBannersData } from '@src/apis/core/getBannersData';
import { ProjectContentSkeleton } from '../Skeleton/ProjectContentSkeleton';
import { Menu } from '@src/models/menuInfo';
import { ProjectPageContainer } from '@src/styles/global';

interface TabProps {
  menuData: Menu;
}

export function Tab({ menuData }: TabProps) {
  const [currentIndex, setCurrentIndex] = React.useState(0);

  const tabs = menuData.tabs;
  const genres = menuData.genres;

  const { data: contentData } = useSWR(
    ['/api/mainpage/tabs', tabs[currentIndex].value],
    ([, tab]) => {
      if (tab) {
        return getTabContentData(tab);
      }
    }
  );

  const { data: bannersData } = useSWR('/api/mainpage/banners', getBannersData);

  return (
    <>
      <TabList
        tabs={tabs}
        currentIndex={currentIndex}
        setCurrentIndex={setCurrentIndex}
      />
      {!contentData || !bannersData ? (
        <ProjectPageContainer>
          <ProjectContentSkeleton isHomeTab={currentIndex === 0} />
        </ProjectPageContainer>
      ) : (
        <TabContent
          genres={genres}
          banners={bannersData}
          contents={contentData.body}
          currentIndex={currentIndex}
        />
      )}
    </>
  );
}

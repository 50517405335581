export interface Tab {
  name: string;
  value: string;
}

export interface Genre {
  name: string;
  value: string;
}

export interface Menu {
  tabs: Tab[];
  genres: Genre[];
}

export const deepLinkGenresValue = ['clue'];

import React from 'react';
import SwiperCore from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

import { styled } from 'stitches.config';
import {
  SECTION_DISPLAY_TABLE,
  SECTION_DISPLAY_TYPE,
} from '@src/constants/constant';
import { Project } from '@src/models/project';
import { HorizontalTileProjectItem } from '../ProjectItem/HorizontalTile';
import { VerticalProjectItem } from '../ProjectItem/Vertical';
import { HorizontalScrollProjectItem } from '../ProjectItem/HorizontalScroll';

interface ProjectListProps {
  type: SECTION_DISPLAY_TYPE;
  projects: Project[];
}

export function ProjectList({ type, projects }: ProjectListProps) {
  const [isBeginning, setIsBeginning] = React.useState(true);
  const [isEnd, setIsEnd] = React.useState(false);

  const swiperRef = React.useRef<SwiperCore | null>();

  const changeSlideState = (swiper: SwiperCore) => {
    setIsBeginning(swiper.isBeginning);
    setIsEnd(swiper.isEnd);
  };

  if (type === SECTION_DISPLAY_TABLE.HORIZONTAL_TILE_LIST)
    return (
      <HorizontalTileList>
        {projects.map((project) => (
          <HorizontalTileProjectItem key={project.id} project={project} />
        ))}
      </HorizontalTileList>
    );
  if (type === SECTION_DISPLAY_TABLE.VERTICAL_LIST)
    return (
      <VerticalList>
        {projects.map((project) => (
          <VerticalProjectItem key={project.id} project={project} />
        ))}
      </VerticalList>
    );
  if (type === SECTION_DISPLAY_TABLE.HORIZONTAL_SCROLL_LIST)
    return (
      <HorizontalScrollList>
        <Swiper
          spaceBetween={7}
          centeredSlides={false}
          slidesPerView="auto"
          slidesPerGroup={1}
          slidesPerGroupAuto={true}
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
            changeSlideState(swiper);
          }}
          onSlideChange={changeSlideState}
          onResize={changeSlideState}
        >
          {projects.map((project) => (
            <SwiperSlide key={project.id} className="horizontal-swiper-slide">
              <HorizontalScrollProjectItem project={project} />
            </SwiperSlide>
          ))}
        </Swiper>
        <SwiperPrevButtonArea>
          <SwiperButton
            onClick={() => swiperRef.current?.slidePrev()}
            style={{ display: isBeginning ? 'none' : '' }}
          >
            <FaChevronLeft size={18} />
          </SwiperButton>
        </SwiperPrevButtonArea>
        <SwiperNextButtonArea>
          <SwiperButton
            onClick={() => swiperRef.current?.slideNext()}
            style={{ display: isEnd ? 'none' : '' }}
          >
            <FaChevronRight size={18} />
          </SwiperButton>
        </SwiperNextButtonArea>
      </HorizontalScrollList>
    );
  return <></>;
}

const HorizontalTileList = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: '32px 6px',
  width: '100%',
  paddingBottom: '24px',

  '@bpMin568': {
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '32px 16px',
  },
  '@bpMin768': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },

  '@bpMax767': {
    paddingRight: '1rem',
  },
});

const VerticalList = styled('div', {
  display: 'grid',
  gap: '16px',
  gridTemplateColumns: 'repeat(2, 1fr)',
  paddingBottom: '24px',

  '@bpMax991': {
    gridTemplateColumns: '1fr',
  },
});

const HorizontalScrollList = styled('div', {
  position: 'relative',
  paddingBottom: '24px',

  '&:hover button': {
    opacity: 1,
  },
});

const SwiperButton = styled('button', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '46px',
  height: '46px',
  border: 'none',
  borderRadius: '50%',
  color: 'gray',
  backgroundColor: 'rgba(255, 255, 255, 0.7)',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, 0.16)',
  outline: 'none',
  opacity: 0,
  transition:
    'color .3s ease-in-out, opacity .3s ease-in-out, background-color .3s ease-in-out',
  cursor: 'pointer',

  '&:hover': {
    color: '#c869ff',
    backgroundColor: '#fff',
  },

  '@bpMax767': {
    display: 'none !important',
  },
});

const SwiperPrevButtonArea = styled('div', {
  position: 'absolute',
  top: '50%',
  left: '-23px',
  transform: 'translateY(calc(-50% - 24px))',
  zIndex: 1,
});

const SwiperNextButtonArea = styled('div', {
  position: 'absolute',
  top: '50%',
  right: '-23px',
  transform: 'translateY(calc(-50% - 24px))',
  zIndex: 1,
});
